<template>
  <v-container fluid>
    <base-form
      v-if="editItem"
      :editStatus="true"
      :editItem="editItem"
      @update="handleSubmit"
      @cancel="handleCancel"
    />
  </v-container>
</template>

<script>

  import { mapGetters, mapActions } from 'vuex';
  import dialogs from '@/services/dialogs.js';
  import { getLocalMessage } from '@/utils';

  export default {
    components: {
      BaseForm: () => import('./BaseForm')
    },
    props: {
      id: {
        type: Number,
        default: () => null
      }
    },
    data () {
      return {
        editItem: null
      }
    },

    computed: {
      ...mapGetters({
        currentHomeDoctor: 'homeDoctor/currentHomeDoctor'
      })
    },

    created () {
      if (this.id) {
        this.homeDoctorShow( this.id ).then( () => {
          this.editItem = this.currentHomeDoctor
        } )
      }
    },

    methods: {
      ...mapActions({
        homeDoctorShow: 'homeDoctor/show',
        homeDoctorUpdate: 'homeDoctor/update'
      }),

      getLocalMessage,

      handleSubmit (data) {
        this.homeDoctorUpdate(data).then(() => {
          this.$router.push({name: 'homeDoctor'})
        }).catch( error => {
          if (error.data && error.data.code == 1100) {
            let messages = error.data.message.split('\'')
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.resourceExistedErr', [this.getLocalMessage(this, 'user', messages[1])]), { duration: 10, type: 'error' })
          } else {
            dialogs.message(this.$vuetify.lang.t('$vuetify.message.systemErr'), { duration: 10, type: 'error' })
          }
        })
      },
      handleCancel() {
        this.$router.go(-1)
      }
    }
  }
</script>
